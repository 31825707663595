@mixin hide {
  position: absolute;
  left: -9999px;
}

@mixin drop-shadow {
  text-shadow: 1px 1px #000, 2px 2px #000, 3px 3px #000, 4px 4px #000, 5px 5px #000, 6px 6px #000;
}

@mixin text-shadow {
  text-shadow: 1px 1px #000;
}

@mixin word-wrap {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

@mixin un-word-wrap {
  word-break: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  hyphens: none;
}

@mixin transition($transition-property, $transition-time, $method) {
  -webkit-transition: $transition-property $transition-time $method;
  -moz-transition: $transition-property $transition-time $method;
  -ms-transition: $transition-property $transition-time $method;
  -o-transition: $transition-property $transition-time $method;
  transition: $transition-property $transition-time $method;
}

@mixin transform($transform-property, $transform-value) {
  -webkit-transform: $transform-property+unquote('(#{$transform-value})');
  -moz-transform: $transform-property+unquote('(#{$transform-value})');
  -ms-transform: $transform-property+unquote('(#{$transform-value})');
  -o-transform: $transform-property+unquote('(#{$transform-value})');
  transform: $transform-property+unquote('(#{$transform-value})');
}

@mixin animation($animation, $duration, $delay) {
  -webkit-animation-name: $animation;
  -webkit-animation-duration: $duration;
  -webkit-animation-delay: $delay;
  -webkit-animation-fill-mode: forwards;

  -moz-animation: $animation ease-out 1;
  -moz-animation-duration: $duration;
  -moz-animation-fill-mode: forwards;

  animation-name: $animation;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-fill-mode: forwards;
}

@mixin vert-gradient($fromColor, $toColor) {
  background-color: $toColor;
  background: -webkit-linear-gradient(top, $fromColor 0%, $toColor 100%);
  background: linear-gradient(to bottom, $fromColor 0%, $toColor 100%);
}

@mixin hori-gradient($fromColor, $toColor) {
  background-color: $toColor;
  background: -webkit-linear-gradient(left, $fromColor 0%, $toColor 100%);
  background: linear-gradient(to right, $fromColor 0%, $toColor 100%);
}

@mixin filter($filter-type,$filter-amount) {
  -webkit-filter: $filter-type+unquote('(#{$filter-amount})');
  -moz-filter: $filter-type+unquote('(#{$filter-amount})');
  -ms-filter: $filter-type+unquote('(#{$filter-amount})');
  -o-filter: $filter-type+unquote('(#{$filter-amount})');
  filter: $filter-type+unquote('(#{$filter-amount})');
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin border-box {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
