/**********************************
Description...: screen sass
Author........: hello@satur9.co.uk
Last Changed..: timestamp()
Notes #1......:
*********************************/
/**********************************
Description...: MOBILE THEME
Last Changed..: timestamp()
Notes #1......:
/******************/
@media screen and (max-device-width: 880px), screen and (max-width: 880px) {
  /*SUPER SIZE MONITOR*/
  body {
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: 100%; }
  .container {
    width: auto; }
  main, footer {
    width: 100%;
    margin: 0%;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; } }

@media screen and (max-device-width: 768px), screen and (max-width: 768px) {
  /*TABLET, get rid of the exessive margins*/
  main .button-container {
    padding: 0px 20px 20px; }
  main p.intro, main main .outcome-info, main .outcome {
    padding: 20px; }
  main .ans-yes, main .ans-no {
    background-size: 50px;
    background-position: top 10px right 20px; }
  .option-pop {
    display: block;
    margin-bottom: 40px; }
  .ans-yes {
    margin-left: 0px; }
  p, ul li, ol li {
    margin-bottom: 16px; }
  .line, .question .line {
    margin-left: 20px;
    margin-right: 20px; }
  .section-content, div .info {
    padding: 20px; }
  .notes-container {
    margin: -50px 0px 20px -20px;
    padding: 0 0 0 20px; }
    .notes-container h4 {
      padding: 0 0 0 10px; }
  header img {
    width: 70%; }
  main button.option-nah,
  main button.option-skip-2-3, main button.option-skip-2-3, main button.option-skip-5-6,
  main button.option-skip-7-8 {
    margin-left: 0;
    margin-right: 0; }
  main .ques-0a .next, main .ques-0b .next {
    margin-left: 0; }
  main button.option-goback, main button.option-undo {
    right: 0px; } }

@media screen and (max-device-width: 640px), screen and (max-width: 640px) {
  /*SMARTPHONE*/
  body {
    font-size: 80%; }
  main {
    padding-left: 0px;
    padding-right: 0px; }
  main h2, main h3, main h4, main h5, main h6 {
    font-size: 1.8em; }
  main p.intro {
    font-size: 1.8em; }
  main .ques-0b h2 {
    padding-left: 0px;
    margin: 20px 0px; }
  main button.option-goback, main button.option-undo {
    right: 20px; } }

@media screen and (max-device-width: 500px), screen and (max-width: 500px) {
  main img {
    float: none;
    max-width: 100%;
    margin: 20px 0;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  main h4 {
    padding-right: 90px; }
  main .ques-6 ans-no h4 {
    padding-right: 0; }
  main .option-skip-2-3, main .option-skip-2-4,
  main .option-skip-4-5, main .option-skip-4-6,
  main .option-skip-5-7,
  main .option-skip-6-7 {
    clear: left;
    margin-left: 20px; }
  main .ques-0a h1 {
    font-size: 5em; } }

@media screen and (max-device-width: 380px), screen and (max-width: 380px) {
  /*MOBILE*/
  main .ques-0a .next, main .ques-0b .next {
    min-width: auto;
    padding-left: 20px;
    padding-right: 40px; } }

/* IE only (forces a nicer mobile view for IE's?) ----------- */
@-ms-viewport {}/******************
Layout & basics**/
* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box; }

body {
  font-family: 'Poppins', helvetica, arial, sans-serif;
  font-size: 100%;
  font-weight: normal;
  text-align: left;
  cursor: default;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%; }

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: left; }

article, aside, details, figcaption, figure, figcaption, footer, header, menu,
section, main, nav, video {
  display: block; }

.section-content {
  width: 100%;
  padding: 40px; }

.notes-container {
  margin: -80px 0 40px -40px;
  width: 100%;
  max-width: 180px;
  padding: 20px 40px;
  background: #EDF2F2;
  box-shadow: 0px 5px 10px #0000001A;
  border-radius: 0px 500px 500px 0px; }
  .notes-container img, .notes-container h4 {
    display: inline-block; }
  .notes-container img {
    width: 30px; }
  .notes-container h4 {
    margin-bottom: 0px;
    font-size: 1em;
    font-weight: bold; }

img {
  vertical-align: middle; }

/******************
JavaScript styling**/
section {
  position: relative; }

section, section div {
  display: none; }

div.info {
  display: block;
  background: #EDF2F2;
  padding: 40px; }

.notes-container {
  display: block; }

.ques-0a .next, .ques-0b .begin {
  display: block; }

/******************
Animation mods (not used)**/
.current {
  /*-webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  -ms-animation-duration:1s;
  -o-animation-duration:1s;
  animation-duration:1s;*/ }

.ques-2 {
  /*-webkit-animation-delay:2s;
  -moz-animation-delay:2s;
  -ms-animation-delay:2s;
  -o-animation-delay:2s;
  animation-delay:2s;*/ }

/******************
Main Content**/
#main-content_holder {
  display: block;
  margin: 40px auto 20px;
  width: 80%;
  max-width: 790px;
  color: #121a3d;
  background: #78c4f2;
  box-sizing: border-box; }

main {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  /******************
  Text styles**/
  /******************
  Buttons**/
  /******************
  Answer Blocks**/
  /******************
  Entry Sections**/ }
  main h2, main h3, main h4, main h5, main h6 {
    font-size: 2.15em;
    line-height: 1.3em;
    margin-bottom: 38px;
    font-weight: normal; }
  main h3::first-letter {
    font-weight: bold; }
  main h4 {
    margin-bottom: 20px; }
  main p {
    font-size: 1em;
    line-height: 1.65em;
    margin-bottom: 32px; }
    main p.intro {
      font-size: 1.85em;
      line-height: 1.55em;
      padding: 0 40px; }
  main ul, main ol {
    margin-bottom: 40px; }
    main ul li, main ol li {
      list-style: disc;
      font-size: 1.3em;
      line-height: 1.65em;
      margin: 0 0 16px 20px; }
  main ol li {
    list-style: decimal;
    margin: 0 0 32px 30px; }
  main img {
    margin: 0 8px; }
  main .button-container {
    background: #EDF2F2;
    padding: 0px 40px 40px; }
  main button, main .next, main .reset, main .begin,
  main .option-skip-2-3, main .option-skip-2-4,
  main .option-skip-4-5, main .option-skip-4-6,
  main .option-skip-5-7,
  main .option-skip-6-7 {
    cursor: pointer;
    color: #fff;
    font-size: 1.4em;
    font-weight: bold;
    padding: 15px;
    margin: 0 38px 15px 0;
    width: 200px;
    background-color: #121a3d;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-align: center; }
    main button:hover, main .next:hover, main .reset:hover, main .begin:hover,
    main .option-skip-2-3:hover, main .option-skip-2-4:hover,
    main .option-skip-4-5:hover, main .option-skip-4-6:hover,
    main .option-skip-5-7:hover,
    main .option-skip-6-7:hover {
      background-color: #fac42e;
      color: #121a3d; }
  main button.option-pop {
    text-transform: none;
    text-decoration: underline;
    padding: 0;
    margin-right: 0;
    background: none;
    width: auto;
    color: #121a3d;
    border-bottom: 1px solid #121a3d;
    padding-bottom: 3px;
    text-decoration: none;
    font-weight: 700; }
  main button.option-goback, main button.option-undo, main button.option-goback-outcome {
    position: absolute;
    top: -70px;
    right: 40px;
    font-size: 1.2em;
    font-family: 'Museo Sans W01_300', helvetica, arial, sans-serif;
    font-weight: bold;
    text-transform: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    background: none;
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
    width: auto;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
    main button.option-goback img, main button.option-goback span, main button.option-undo img, main button.option-undo span, main button.option-goback-outcome img, main button.option-goback-outcome span {
      display: inline-block;
      vertical-align: middle; }
    main button.option-goback img, main button.option-undo img, main button.option-goback-outcome img {
      width: 10px;
      margin: 0;
      margin-right: 5px; }
    main button.option-goback:hover, main button.option-undo:hover, main button.option-goback-outcome:hover {
      color: #fac42e;
      border-bottom: 1px solid #fac42e; }
      main button.option-goback:hover img, main button.option-undo:hover img, main button.option-goback-outcome:hover img {
        filter: brightness(0) saturate(100%) invert(79%) sepia(90%) saturate(987%) hue-rotate(331deg) brightness(99%) contrast(97%); }
  main button.option-undo {
    display: none; }
  main .next, main .reset, main .begin,
  main .option-skip-2-3, main .option-skip-2-4,
  main .option-skip-4-5, main .option-skip-4-6,
  main .option-skip-5-7,
  main .option-skip-6-7 {
    font-size: 1.5em;
    padding: 10px 20px; }
  main .ans-yes, main .ans-no {
    color: #252128;
    background-repeat: no-repeat;
    background-position: 98% 14px;
    border-radius: 10px;
    padding-top: 40px; }
    main .ans-yes a:link, main .ans-yes a:visited, main .ans-no a:link, main .ans-no a:visited {
      color: #252128; }
    main .ans-yes a:active, main .ans-yes a:focus, main .ans-no a:active, main .ans-no a:focus {
      color: #fff; }
    main .ans-yes .text-link, main .ans-no .text-link {
      cursor: pointer;
      color: #252128;
      text-decoration: underline; }
      main .ans-yes .text-link:hover, main .ans-no .text-link:hover {
        text-decoration: none; }
  main .outcome {
    margin: 0 0 30px;
    width: 70%;
    max-width: 370px;
    padding: 20px 40px;
    background: #2c88dd;
    color: #fff;
    font-weight: bold;
    box-shadow: 0px 5px 10px #0000001A;
    border-radius: 0px 500px 500px 0px; }
  main .outcome-info {
    display: block;
    background: #EDF2F2;
    padding: 40px; }
    main .outcome-info p {
      margin-bottom: 0px; }
  main .ans-yes,
  main .resp-3.ans-yes {
    background-image: url(../../img/icon-tick.svg);
    background-position: top 40px right 40px; }
  main .ans-no,
  main .resp-4.ans-no {
    background-image: url(../../img/icon-cross.svg);
    background-position: top 40px right 40px; }
  main .ques-0a {
    text-align: left;
    height: 100%;
    position: relative; }
    main .ques-0a .blue-shape {
      display: block;
      margin: 0 0 0 auto;
      width: 75%;
      right: 0;
      bottom: 0;
      z-index: -1; }
    main .ques-0a h1 {
      font-size: 5.6em;
      margin: 26px 0 36px;
      font-weight: 300; }
    main .ques-0a .next {
      float: none;
      display: inline-block;
      min-width: 260px;
      padding-left: 0;
      padding-right: 0; }
  main .ques-0b {
    text-align: left; }
    main .ques-0b h2 {
      text-align: left; }
    main .ques-0b ul li, main .ques-0b ol li {
      text-align: left; }
    main .ques-0b .begin {
      float: none;
      display: inline-block;
      padding-left: 0;
      padding-right: 0; }

.ques-3 .button-container {
  padding-top: 40px; }

/******************
Footer**/
footer {
  position: relative;
  float: left;
  width: 746px;
  padding: 32px;
  margin: 0 35px;
  background: #5bbcb8; }
  footer img {
    float: right; }

/******************
  Header **/
header {
  background: #121a3d;
  padding: 30px 40px; }
  header img {
    width: 100%;
    max-width: 350px; }

/******************
Global styles**/
.line {
  position: relative;
  clear: both;
  height: 1px;
  font-size: 0;
  margin: 55px 45px 70px;
  border-bottom: 3px solid #fff; }
  .line span {
    position: absolute;
    left: 50%;
    top: -30px;
    display: block;
    width: 90px;
    height: 60px;
    margin-left: -45px;
    background: #5bbcb8 url(../img/icon-line-01.png) 3px 0 no-repeat; }

.question .line {
  margin: 45px;
  border-color: #5bbcb8; }
  .question .line span {
    background-color: #fff;
    background-image: url(../img/icon-line-02.png); }

.clearing {
  clear: both; }

.error {
  color: #f00; }

.win {
  color: #090; }

.hide {
  position: absolute;
  left: -9999px; }

.no-function {
  pointer-events: none; }

strong {
  font-weight: 700; }

acronym, abbr {
  cursor: help;
  border-bottom: 1px dotted #fff; }

/******************
Hyperlinks & highlighting**/
a:link, a:visited {
  color: #121a3d;
  font-weight: 600;
  text-decoration: underline; }

a:hover {
  text-decoration: none; }

a:active, a:focus {
  color: #fff;
  background-color: #007a87;
  text-decoration: underline; }

a.pdf {
  padding-left: 24px;
  background: url(../img/icon-pdf.png) 0 50% no-repeat; }

::selection {
  background: #007a87;
  color: #fff; }

::-moz-selection {
  background: #007a87;
  color: #fff; }

/******************
Clearfix**/
.clearfix:after {
  visibility: hidden;
  display: block;
  clear: both;
  content: ".";
  font-size: 0;
  height: 0; }

* html .clearfix {
  zoom: 1; }

/*IE6*/
*:first-child + html .clearfix {
  zoom: 1; }

/*IE7*/
