/**********************************
Description...: screen sass
Author........: hello@satur9.co.uk
Last Changed..: #{timestamp()}
Notes #1......:
*********************************/

@import "variables.scss";
@import "mixins.scss";
@import "theme-mobile";

/******************
Layout & basics**/
* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', helvetica, arial, sans-serif;
  font-size: 100%;
  font-weight: normal;
  text-align: left;
  cursor: default;
  background-color: $base-white;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: left;
}

article, aside, details, figcaption, figure, figcaption, footer, header, menu,
section, main, nav, video {
  display: block;
}
.section-content {
  width:100%;
  padding:40px;
}
.notes-container {
  margin:-80px 0 40px -40px;
  width:100%;
  max-width:180px;
  padding:20px 40px;
  background: $base-lightgrey;
  box-shadow: 0px 5px 10px #0000001A;
  border-radius: 0px 500px 500px 0px;
  img, h4 {
    display: inline-block;
  }
  img {
    width: 30px;
  }
  h4 {
    margin-bottom: 0px;
    font-size:1em;
    font-weight: bold;
  }
}

img {
  vertical-align: middle;
}

/******************
JavaScript styling**/
section {
  position: relative; 
}

section, section div {
  display: none;
}

div.info {
  display: block;
  background: $base-lightgrey;
  padding:40px;
}
.notes-container {
  display: block;
}
.ques-0a .next, .ques-0b .begin {
  display: block;
}

/******************
Animation mods (not used)**/
.current {
  /*-webkit-animation-duration:1s;
  -moz-animation-duration:1s;
  -ms-animation-duration:1s;
  -o-animation-duration:1s;
  animation-duration:1s;*/
}

.ques-2 {
  /*-webkit-animation-delay:2s;
  -moz-animation-delay:2s;
  -ms-animation-delay:2s;
  -o-animation-delay:2s;
  animation-delay:2s;*/
}

/******************
Main Content**/
#main-content_holder {
  display: block;
  margin:40px auto 20px;
  width: 80%;
  max-width: 790px;
  color: $base-darkblue;
  background: $base-lightblue;
  box-sizing: border-box;
}

main {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  height:100%;
  // padding: 40px;

  /******************
  Text styles**/

  h2, h3, h4, h5, h6 {
    font-size: 2.15em;
    line-height: 1.3em;
    margin-bottom: 38px;
    font-weight: normal;
  }

  h3 {
    &::first-letter {
      font-weight: bold;
    }
  }

  h4 {
    margin-bottom: 20px;
  }

  p {
    font-size: 1em;
    line-height: 1.65em;
    margin-bottom: 32px;

    &.intro {
      font-size: 1.85em;
      line-height: 1.55em;
      padding: 0 40px;
    }
  }

  ul, ol {
    margin-bottom: 40px;

    li {
      list-style: disc;
      font-size: 1.3em;
      line-height: 1.65em;
      margin: 0 0 16px 20px;
    }
  }

  ol li {
    list-style: decimal;
    margin: 0 0 32px 30px;
  }

  img {
    margin: 0 8px;
  }

  a:link, a:visited {
  }

  a:hover, a:active, a:focus {
  }

  /******************
  Buttons**/
  .button-container {
    background: $base-lightgrey;
    padding: 0px 40px 40px;
  }
  button, .next, .reset, .begin,
  .option-skip-2-3, .option-skip-2-4,
  .option-skip-4-5, .option-skip-4-6,
  .option-skip-5-7,
  .option-skip-6-7 {
    cursor: pointer;
    color: $base-white;
    font-size: 1.4em;
    font-weight: bold;
    padding: 15px;
    margin: 0 38px 15px 0;
    width:200px;
    background-color: $base-darkblue;
    @include transition(all, 0.2s, ease-in-out);
    text-align: center;

    &:hover {
      background-color:$base-yellow;
      color: $base-darkblue;
    }
  }

  button.option-pop {
    text-transform: none;
    text-decoration: underline;
    padding: 0;
    margin-right: 0;
    background: none;
    width:auto;
    color: $base-darkblue;
    border-bottom:1px solid $base-darkblue;
    padding-bottom:3px;
    text-decoration: none;
    font-weight: 700;
  }

    button.option-goback, button.option-undo, button.option-goback-outcome {
    position: absolute;
    top: -70px;
    right: 40px;
    font-size: 1.2em;
    font-family: 'Museo Sans W01_300', helvetica, arial, sans-serif;
    font-weight: bold;
    text-transform: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    background: none;
    border-bottom:1px solid $base-white;
    padding-bottom: 5px;
    width:auto;
    @include transition(all, 0.2s, ease-in-out);

    img, span {
      display: inline-block;
      vertical-align: middle;
    }
    img {
      width: 10px;
      margin: 0;
      margin-right: 5px;
    }

    &:hover {
      color: $base-yellow;
      border-bottom: 1px solid $base-yellow;

      img {
        filter: brightness(0) saturate(100%) invert(79%) sepia(90%) saturate(987%) hue-rotate(331deg) brightness(99%) contrast(97%);
      }
    }
  }

  button.option-undo {
    display: none;
  }

  .next, .reset, .begin,
  .option-skip-2-3, .option-skip-2-4,
  .option-skip-4-5, .option-skip-4-6,
  .option-skip-5-7,
  .option-skip-6-7 { 
    font-size: 1.5em;
    padding: 10px 20px;
  }

  /******************
  Answer Blocks**/
  .ans-yes, .ans-no {
    color: $base-black;
    background-repeat: no-repeat;
    background-position: 98% 14px;
    border-radius: 10px;
    padding-top:40px;

    a:link, a:visited {
      color: $base-black;
    }

    a:active, a:focus {
      color: #fff;
    }

    .text-link {
      cursor: pointer;
      color: $base-black;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

  }
  .outcome {
    margin:0 0 30px;
    width:70%;
    max-width:370px;
    padding:20px 40px;
    background: $base-midblue;
    color: $base-white;
    font-weight: bold;
    box-shadow: 0px 5px 10px #0000001A;
    border-radius: 0px 500px 500px 0px;
  }
  .outcome-info {
    display: block;
    background: $base-lightgrey;
    padding: 40px;
    p {
      margin-bottom: 0px;
    }
  }

  .ans-yes,
  .resp-3.ans-yes {
    background-image: url(../../img/icon-tick.svg);
    background-position: top 40px right 40px;
  }

  .ans-no,
  .resp-4.ans-no {
    background-image: url(../../img/icon-cross.svg);
    background-position: top 40px right 40px;
  }

  /******************
  Entry Sections**/
  .ques-0a {
    text-align: left;
    height:100%;
    position:relative;

    .blue-shape {
      display: block;
      margin: 0 0 0 auto;
      width: 75%;
      right:0;
      bottom:0;
      z-index: -1;
    }

    h1 {
      font-size: 5.6em;
      margin: 26px 0 36px;
      font-weight: 300;
    }

    .next {
      float: none;
      display: inline-block;
      min-width: 260px;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .ques-0b {
    text-align: left;

    h2 {
      text-align: left;
    }

    ul, ol {

      li {
        text-align: left;
      }
    }

    .begin {
      float: none;
      display: inline-block;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
.ques-3 {
  .button-container {
    padding-top:40px;
  }
}

/******************
Footer**/
footer {
  position: relative;
  float: left;
  width: 746px;
  padding: 32px;
  margin: 0 35px;
  background: $base-lightgreen;

  img {
    float: right;
  }
}
  /******************
  Header **/
  header {
    background: $base-darkblue;
    padding: 30px 40px; 

    img {
      width:100%;
      max-width: 350px;
    }
  }

/******************
Global styles**/
.line {
  position: relative;
  clear: both;
  height: 1px;
  font-size: 0;
  margin: 55px 45px 70px;
  border-bottom: 3px solid #fff;

  span {
    position: absolute;
    left: 50%;
    top: -30px;
    display: block;
    width: 90px;
    height: 60px;
    margin-left: -45px;
    background: $base-lightgreen url(../img/icon-line-01.png) 3px 0 no-repeat;
  }
}

.question .line {
  margin: 45px;
  border-color: $base-lightgreen;

  span {
    background-color: #fff;
    background-image: url(../img/icon-line-02.png);
  }
}

.clearing {
  clear: both;
}

.error {
  color: #f00;
}

.win {
  color: #090;
}

.hide {
  @include hide;
}

.no-function {
  pointer-events: none;
}

strong {
  font-weight: 700;
}

acronym, abbr {
  cursor: help;
  border-bottom: 1px dotted #fff;
}

/******************
Hyperlinks & highlighting**/
a:link, a:visited {
  color: $base-darkblue;
  font-weight: 600;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

a:active, a:focus {
  color: #fff;
  background-color: $base-darkgreen;
  text-decoration: underline;
}

a.pdf {
  padding-left: 24px;
  background: url(../img/icon-pdf.png) 0 50% no-repeat;
}

::selection {
  background: $base-darkgreen;
  color: #fff;
}

::-moz-selection {
  background: $base-darkgreen;
  color: #fff;
}

/******************
Clearfix**/
.clearfix:after {
  visibility: hidden;
  display: block;
  clear: both;
  content: ".";
  font-size: 0;
  height: 0;
}

* html .clearfix {
  zoom: 1;
}

/*IE6*/
*:first-child + html .clearfix {
  zoom: 1;
}

/*IE7*/
