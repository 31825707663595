/**********************************
Description...: MOBILE THEME
Last Changed..: #{timestamp()}
Notes #1......:
/******************/

@import "variables.scss";
@import "mixins.scss";

@media screen and (max-device-width:880px), screen and (max-width:880px) {
	/*SUPER SIZE MONITOR*/
	body {-webkit-text-size-adjust:none;-ms-text-size-adjust:100%;}
	.container {width:auto;}

	main, footer {width:100%;margin:0%;@include border-box;}
}

@media screen and (max-device-width:768px), screen and (max-width:768px) {
	/*TABLET, get rid of the exessive margins*/
		main {
			.button-container {padding: 0px 20px 20px}
			p.intro, main .outcome-info, .outcome {
				padding: 20px;
			}
			.ans-yes, .ans-no {
				background-size: 50px;
				background-position: top 10px right 20px;
			}
		}
		.option-pop {display: block;margin-bottom:40px;}
		.ans-yes {margin-left:0px;}

		p, ul li, ol li {margin-bottom:16px;}
	.line, .question .line {margin-left:20px;margin-right:20px;}
	.section-content, div .info {
		padding: 20px;
	}
	.notes-container {
		margin: -50px 0px 20px -20px; padding: 0 0 0 20px;
		h4 {
			padding: 0 0 0 10px;
		}
	}
	header img {width:70%;}

	main button.option-nah,
	main button.option-skip-2-3, main button.option-skip-2-3, main button.option-skip-5-6,
	main button.option-skip-7-8 {margin-left:0;margin-right:0;}
	main .ques-0a .next, main .ques-0b .next {margin-left:0;}
	main button.option-goback, main button.option-undo {right:0px;}
}

@media screen and (max-device-width:640px), screen and (max-width:640px) {
	/*SMARTPHONE*/

	body {font-size:80%;}

	main {padding-left:0px;padding-right:0px;}

	main h2, main h3, main h4, main h5, main h6 {font-size:1.8em;}
	main p.intro {font-size:1.8em;}
	main .ques-0b h2 {padding-left:0px;margin: 20px 0px;}
	main button.option-goback, main button.option-undo {right:20px;}
}

@media screen and (max-device-width:500px), screen and (max-width:500px) {

	main img {float:none;max-width:100%;margin:20px 0;@include border-box;}
	main h4 {padding-right:90px;}
		main .ques-6 ans-no h4 {padding-right:0;}

	main .option-skip-2-3, main .option-skip-2-4,
	main .option-skip-4-5, main .option-skip-4-6,
	main .option-skip-5-7,
	main .option-skip-6-7 {clear:left;margin-left:20px;}
	main .ques-0a h1 {
		font-size: 5em;
	}

}

@media screen and (max-device-width:380px), screen and (max-width:380px) {
	/*MOBILE*/

	main .ques-0a .next, main .ques-0b .next {
    min-width:auto;
    padding-left:20px;padding-right:40px;
	}
}

/* IE only (forces a nicer mobile view for IE's?) ----------- */
@-ms-viewport {
//width:device-width;
}
